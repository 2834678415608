.moduleBtn {
  background-color: transparent;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.activeModuleBtn {
  background-color: #eaf4ff;
  padding: 0.5rem 1rem;
  border: 1px solid #1f6dde;
  border-radius: 0.9rem;
  cursor: pointer;
}
.activeModuleBtnNew {
  background-color: white;
  padding: 0.5rem 1rem;
  border: 1px solid #1f6dde;
  border-radius: 0.9rem;
  /* cursor: pointer; */
}

.selectedFolder {
  border: 1px solid #1f6dde;
  color: #1f6dde;
}
